import { call, put, takeEvery, select, delay } from 'redux-saga/effects';
import { post } from '../services';
// import { API } from '@config';

import {
  SEND_MESSGAE_START,
  SEND_MESSGAE_SUCCESS,
  SEND_MESSGAE_FAILED,
} from '../actions/message';

export default function* formListen() {
  yield takeEvery('SUBMIT_MESSAGE_FORM', submitForm);
}

function* submitForm() {
  const state = yield select();
  const { contact } = state.form;
  yield put({ type: SEND_MESSGAE_START });
  yield delay(500);
  try {
    const data = yield call(() =>
      post(`https://idt-tt.herokuapp.com/api/email/send_message_commercial`, contact.values),
      // post(`http://localhost:1002/api/email/send_message_commercial`, contact.values),
    );

    if (data) {
      yield put({ type: SEND_MESSGAE_SUCCESS, payload: data });
    } else {
      yield put({ type: SEND_MESSGAE_FAILED, payload: data });
    }
  } catch (e) {
    yield put({ type: SEND_MESSGAE_FAILED, message: 'error' });
  }
}
