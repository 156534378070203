import React from "react";
import { Container, Row, Col } from "reactstrap";

const renderFeatures = () => {
  let features = [];
  const elements = [
    {
      icon: "pe-7s-diamond",
      title: "響應式網頁設計",
      description:
        "響應式設計的網站可以讓您的顧客透過不同的裝置瀏覽網站"
    },
    {
      icon: "pe-7s-tools",
      title: "快速整合各種平台",
      description:
        "使用Wordpress/訂制系統管理，同時於多個平台推出應用程式毫無難度"
    },
    {
      icon: "pe-7s-light",
      title: "配合客戶",
      description:
        "我們會定時更新程式碼，保證客戶嘅產品符合市場需求"
    }
  ];

  elements.forEach((element, i) => {
    features.push(
      <Col key={i} md="4" className="py-4 rounded shadow-hover">
        <i className={`pe ${element.icon} pe-3x mb-3 text-primary`} />
        <h5 className="bold">{element.title}</h5>
        <p>{element.description}</p>
      </Col>
    );
  });

  return features;
};

const Features = () => {
  return (
    <section id="features" className="section features">
      <Container>
        <div className="section-heading text-center">
          <h2 className="heading-line">建立美觀網站只是第一步而已。</h2>
          <p className="lead text-muted">
            首先要製作網站，然後才希望大家能來看看。不過，製作網站並不代表大家一定會來光顧。您需要用盡各種方法吸引人們前往您的網站。這就是我們能額外給您的好處。
          </p>
        </div>

        <Row className="gap-y text-center text-md-left">{renderFeatures()}</Row>
      </Container>
    </section>
  );
};

export default Features;
