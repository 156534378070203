// Imports: Dependencies
import AsyncStorage from '@callstack/async-storage';
import {createStore, applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {createLogger} from 'redux-logger';
import {persistStore, persistReducer} from 'redux-persist';
import rootSaga from '../sagas';

// Imports: Redux
import rootReducer from '../reducers/index';

// Middleware: Redux Saga
const sagaMiddleware = createSagaMiddleware();

// Middleware: Redux Logger (For Development)
if (process.env.NODE_ENV !== 'production') {
  // sagaMiddleware.apply(logger);
}

const logger = createLogger({
  predicate: (getState, action) => process.env.NODE_ENV !== 'production'
});


// Middleware: Redux Persist Config
const persistConfig = {
  // Root?
  key: 'root',
  // Storage Method (React Native)
  storage: AsyncStorage,
  // Whitelist (Save Specific Reducers)
  whitelist: ['member'],
  // Blacklist (Don't Save Specific Reducers)
  // blacklist: ['counterReducer'],
};

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Redux: Store
const store = createStore(
  persistedReducer,
  applyMiddleware(sagaMiddleware, logger),
);
// Middleware: Redux Saga
sagaMiddleware.run(rootSaga);

// Middleware: Redux Persist Persister
let persistor = persistStore(store);

// Exports
export {store, persistor};
