import React from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AlternateEmail from '@material-ui/icons/AlternateEmail';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';

import { SubmissionError } from 'redux-form'
import asyncValidate from './asyncValidate'
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { hideDialog } from '../../actions/formDialog'
import { initMessageState } from '../../actions/message'

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="#">iDeasTime ltd.
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  
  const useStyles = makeStyles(theme => ({
    paper: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
        paddingBottom: 30
      },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

const renderInput = ({ input: { onChange, ...restInput }, variant, required = false, fullWidth = false, label, autoFocus, autoComplete, rows = '1', multiline = false,  meta: { asyncValidating, touched, error }}) => {
    return (
        <FormControl className={useStyles.formControl} fullWidth={fullWidth}>
          <TextField
            label={label}
            autoComplete={autoComplete}
            onChange={onChange}
            required={required}
            fullWidth={fullWidth}
            autoFocus={autoFocus}
            multiline={multiline}
          rows={rows}
            {...restInput}
            variant={variant}
        />
        {touched && error && <span style={{color: 'red'}}>{error}</span>}
    </FormControl>
    );
  };

  const renderSelect = ({input: {onChange}, fullWidth = false, name, data, selectedType, label}) => {
    const itemArray = data.map(function (d) {
        return <MenuItem key={d.value} value={d.value}>{d.label}</MenuItem>
      });
    return (
        <FormControl className={useStyles.formControl} fullWidth={fullWidth} dense="dense">
        <InputLabel htmlFor="services">{label}</InputLabel>
        {itemArray && 
        <Select
            name={name}
            value={selectedType}
            onChange={onChange}>
            {itemArray}
        </Select>}</FormControl>
    );
  };


let Form = props => {
  const { formData, error, handleSubmit, submitting, mailSent, status, hideDialog, initMessageState } = props;
  const classes = useStyles();
  const selectType = formData ? formData.values.selectType : 0
  const selectPrice = formData ? formData.values.selectPrice : 0
  const selectTypeData = [{label: '個人/公司網站製作', value: 0}, {label: '手機應用程式製作', value: 1}, {label: '網站/手機應用程式更新及維護', value: 2}]
  const selectPriceData = [{ label: '$5,000 - $10,000', value: 0 }, { label: '$10,001 - $20,000', value: 1 }, { label: '預算高於 $20,000', value: 2 }]
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
  const displayMessage = status === 'SEND_MESSGAE_START' ? '處理中。' : '已成功提交，稍後我們會透過電郵回覆。'

  function submit(values) {
    return sleep(1000).then(() => {
      // simulate server latency
      if (!values.email) {
        throw new SubmissionError({
          email: '請輸入資料',
          _error: '重新輸入'
        })
      } else {
        props.handleSubmitForm()
      }
    })
  }

  return (
    <Container component="main" maxWidth="xs">
    <CssBaseline />
      {mailSent ?
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <CheckCircleOutlineIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {displayMessage}
      </Typography>
          <Button
            onClick={() => {
                    hideDialog()
                    initMessageState()
                  }}
            fullWidth
            variant="outlined"
            color="primary"
            className={classes.submit}
            disabled={submitting || status === 'SEND_MESSGAE_START'}
          >
            關閉
        </Button>
        </div>
    :
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <AlternateEmail />
          </Avatar>
          <Typography component="h1" variant="h5">
            聯絡我們
      </Typography>
          <form className={classes.form} onSubmit={handleSubmit(submit)}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Field
                  name="email"
                  autoComplete="fname"
                  variant="outlined"
                  required
                  fullWidth
                  label="聯絡電郵"
                  autoFocus={false}
                  component={renderInput}
                />
              </Grid>
              <Grid item xs={12}>
                <Field name="selectType"
                  label="產品種類"
                  fullWidth
                  autoFocus
                  component={renderSelect}
                  selectedType={selectType}
                  data={selectTypeData}
                />
              </Grid>
              <Grid item xs={12}>
                <Field name="selectPrice"
                  label="預算(以港幣計算)"
                  fullWidth
                  autoFocus
                  component={renderSelect}
                  selectedType={selectPrice}
                  data={selectPriceData}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="content"
                  variant="outlined"
                  required={false}
                  multiline={true}
                  rows="4"
                  fullWidth
                  label="其他查詢內容"
                  component={renderInput}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <FormControlLabel
              control={<Checkbox value="allowExtraEmails" color="primary" />}
              label="I want to receive inspiration, marketing promotions and updates via email."
            /> */}
                <span style={{ fontSize: 12 }}>我們會根據所選擇的服務，提供相關的詳細資料及測試產品，回覆至上述填寫的電郵</span>
                {error && <strong>{error}</strong>}
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    hideDialog()
                    initMessageState()
                  }}
                  className={classes.submit}
                  disabled={ status === 'SEND_MESSGAE_START'}
                >關閉</Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={ status === 'SEND_MESSGAE_START'}
                >提交</Button>
              </Grid>
            </Grid>
            
            
            {/* <Grid container justify="flex-end">
          <Grid item>
            <Link href="#" variant="body2">
              Already have an account? Sign in
            </Link>
          </Grid>
        </Grid> */}
          </form>
        </div>
    }

    <Box mt={5}>
      <Copyright />
    </Box>
  </Container>
  );
};

Form = reduxForm({
// a unique name for the form
form: 'contact',
initialValues: {
    selectType: 0,
    selectPrice: 0
  },
  asyncValidate,
  asyncBlurFields: ['email']
})(Form);

const mapStateToProps = state => ({
  formData: state.form.contact,
  mailSent: state.message.status === 'SEND_MESSGAE_SUCCESS'
})
  
const mapDispatchToProps = dispatch => ({
  handleSubmitForm: () => dispatch({type: 'SUBMIT_MESSAGE_FORM'}),
  hideDialog: () => dispatch(hideDialog()),
  initMessageState: () => dispatch(initMessageState())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Form);
