import React from "react";
import {hydrate, render} from "react-dom";
import { Provider } from 'react-redux'
// Imports: Redux Persist Persister
import {store} from './store/store';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// routing components
// import Index from "views/Index.jsx";
// import Alternative1 from "views/landing/Alternative1.jsx";
import Layout from "views/landing/Layout.jsx";
// import Alternative3 from "views/landing/Alternative3.jsx";

// global template script file
import "./laapp.js";

const rootElement = document.getElementById("root");

const App = <Provider store={store}>
              <BrowserRouter>
                <Switch>
                  <Route path="/" exact render={props => <Layout {...props} />} />
                  <Redirect to="/" />
                </Switch>
              </BrowserRouter>
            </Provider>

if (rootElement.hasChildNodes()) {
  hydrate(App, rootElement);
} else {
  render(App, rootElement);
}
