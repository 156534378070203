import {all} from 'redux-saga/effects';
// import FetchSaga from './fetchSaga';
import formListen from './emailSaga';

export default function* rootSaga() {
  yield all([
    formListen(),
    // FetchSaga(),
    // FacebookRegistrationSaga(),
    // FacebookAuthSaga(),
    // RecepiteFormUpdate(),
    // RecepiteListFormUpdate(),
    // FetchRecepitListen(),
    // FetchDetailListen(),
    // FetchArticleListen(),
    // FetchArticleDetailListen(),
    // bookmarkListen(),
    // formListen(),
    // fetchCategoriesLiten(),
  ]);
}
