import React from "react";
import {Helmet} from "react-helmet";

// shared page components
import MultipleDesignBlocks from "../shared/MultipleDesignBlocks.jsx";
import FeaturesCantMiss from "../shared/FeaturesCantMiss.jsx";
import AppPricingPlan from "../shared/AppPricingPlan.jsx";
import Integration from "../shared/Integration.jsx";
import WhyUs from "../shared/WhyUs.jsx";
import Faqs from "../shared/Faqs.jsx";
import FormDialog from "../shared/FormDialog.jsx";

// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";

// alternative 2 page components
import Heading from "../alter2/Heading.jsx";
import Partners from "../alter2/Partners.jsx";
import Features from "../alter2/Features.jsx";

// Redux
import { connect } from 'react-redux';

// Actions
import { showDialog } from '../../actions/formDialog'

class Layout extends React.Component {
  render() {
    const {showDialog} = this.props
    return (
      <>
        <Helmet>
          <title>iDeasTime - 網站及手機應用開發</title>
          <meta name="description" content="A React.js aapplication" />
        </Helmet>
        <FormDialog/>
        <DefaultNavbar useOnlyDarkLogo={true} handleClick={showDialog}/>
        <main ref="main">
          <Heading/>
          <Partners />
          <Features />
          <MultipleDesignBlocks handleClick={showDialog}/>
          <FeaturesCantMiss />
          <AppPricingPlan />
          <Integration />
          <WhyUs />
          <Faqs />
        </main>
        <DefaultFooter />
      </>
    );
  }
}

const mapStateToProps = state => ({
  status: state.message.status,
  limited: state.message.limited,
  showDialog: state.formDialog
})

const mapDispatchToProps = (dispatch) => {
  return {
    showDialog: () => dispatch(showDialog())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Layout);