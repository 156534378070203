import React from "react";

// Redux
import { connect } from 'react-redux';

// Redux form
import From from "../shared/Form.jsx";
// Actions
import { showDialog, hideDialog } from '../../actions/formDialog'
import { initMessageState } from '../../actions/message'
import Modal from 'react-modal';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
  },
  overlay: {zIndex: 1000}
};

Modal.setAppElement('#root')

const FormDialog = props => {
  const {dialog,hideDialog,status} = props
  return (
    <Modal
      isOpen={dialog.display}
      onRequestClose={()=>{
        hideDialog();
        initMessageState();
      }}
      style={customStyles}
      contentLabel="Contact form"
    >
    <From handleClose={hideDialog} status={status}/>
    </Modal>
  );
};


const mapStateToProps = state => ({
  status: state.message.status,
  dialog: state.formDialog
})

const mapDispatchToProps = (dispatch) => {
  return {
    showDialog: () => dispatch(showDialog()),
    hideDialog: () => dispatch(hideDialog()),
    initMessageState: () => dispatch(initMessageState())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormDialog);

