import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const DefaultFooter = () => {
  return (
    <footer className="site-footer section bg-dark text-contrast edge top-left">
      <Container className="py-3">
        <Row className="row gap-y text-center text-md-left">
          <Col md="4" className="mr-auto">
            <img
              src={require("assets/img/logo-light.png")}
              alt=""
              className="logo"
            />

            <p>
              幫助個人/中小企開發網站及手機應用程式，及持續性發展互聯網相關事業
            </p>
          </Col>

          {/* <Col md="2">
            <Nav className="flex-column" tag="nav">
              <NavLink className="py-2 text-contrast" href="#">
                關於我們
              </NavLink>
              <NavLink className="py-2 text-contrast" href="#">
                服務概覽
              </NavLink>
            </Nav>
          </Col>

          <Col md="2">
            <Nav className="flex-column" tag="nav">
              <NavLink className="py-2 text-contrast" href="#">
                新功能試用
              </NavLink>
              <NavLink className="py-2 text-contrast" href="#">
                最新消息
              </NavLink>
            </Nav>
          </Col>

          <Col md="2">
            <Nav className="flex-column" tag="nav">
              <NavLink className="nav-item py-2 text-contrast" href="#">
                商務合作
              </NavLink>
              <NavLink className="nav-item py-2 text-contrast" href="#">
                聯絡我們
              </NavLink>
            </Nav>
          </Col> */}

          <Col md="2">
            <h6 className="py-2 small">聯絡客服</h6>

            <nav className="nav">
              <a
                href="https://wa.me/85252860623/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-circle btn-sm brand-whatsapp"
              >
                <FontAwesomeIcon icon={faWhatsapp}  />
              </a>
              {/* <a href="#!" className="btn btn-circle btn-sm brand-twitter">
                <FontAwesomeIcon icon={["fab", "twitter"]} />
              </a>
              <a href="#!" className="btn btn-circle btn-sm brand-instagram">
                <FontAwesomeIcon icon={["fab", "instagram"]} />
              </a> */}
            </nav>
          </Col>
        </Row>

        <hr className="mt-5 op-5" />

        <Row className="small">
          <Col md="4">
            <p className="mt-2 mb-0 text-center text-md-left">
              © {new Date().getFullYear()}
              <a
                href="https://ideastime.ltd"
                target="_blank"
                rel="noopener noreferrer"
              >
                iDeasTime ltd
              </a>
              . All Rights Reserved
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default DefaultFooter;
