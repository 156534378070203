import {SEND_MESSGAE_START, SEND_MESSGAE_SUCCESS, SEND_MESSGAE_FAILED, INIT_MESSAGE_STATE} from '../actions/message'

const initState = {
  data: {},
  status: undefined,
  limited: false,
  mailSent: false
};

const messageReducer = (state = initState, action) => {
  switch (action.type) {
    case SEND_MESSGAE_START:
      return {
        ...state,
        status: action.type,
      };
    case SEND_MESSGAE_SUCCESS:
      return {
        ...state,
        status: action.type,
        mailSent: true,
        limited: true,
      };
    case SEND_MESSGAE_FAILED:
      return {
        ...state,
        mailSent: false,
        status: action.type,
      };
    case INIT_MESSAGE_STATE:
        return {
          ...initState,
        };
    default:
      return state;
  }
};

export default messageReducer;