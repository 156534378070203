import {SHOW_FORM_DIALOG, HIDE_FORM_DIALOG} from '../actions/formDialog'

const initState = {
  display: false,
  message: {},
  mailSent: false,
  lastAction: null
};

const formDialogReducer = (state = initState, action) => {
  switch (action.type) {
    case SHOW_FORM_DIALOG:
      return {
        ...state,
        mailSent: false,
        display: true
      };
      case HIDE_FORM_DIALOG:
        return {
          ...state,
          display: false
        };
    default:
      return {...state};
  }
};

export default formDialogReducer;