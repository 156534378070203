export const SHOW_FORM_DIALOG = 'SHOW_FORM_DIALOG';
export const HIDE_FORM_DIALOG = 'HIDE_FORM_DIALOG';

export const showDialog = () =>  ({
  type: SHOW_FORM_DIALOG
})

export const hideDialog = () =>  ({
  type: HIDE_FORM_DIALOG
})
