// Imports: Dependencies
import {combineReducers} from 'redux';
import fetchReducer from './fetchReducer';
import messageReducer from './messageReducer';
import formDialogReducer from './formDialog';
import {reducer as formReducer} from 'redux-form';

// Redux: Root Reducer
const rootReducer = combineReducers({
  data: fetchReducer,
  form: formReducer,
  message: messageReducer,
  formDialog: formDialogReducer,
});

// Exports
export default rootReducer;
