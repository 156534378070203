import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Link } from "react-router-dom";

const MoreInfoButton = props => {
  return (
    <div onClick={()=>props.handleOnClick()} className={`more-link ${props.className}`} style={props.styles}>
      {props.text}
      <FontAwesomeIcon
        icon={["fas", "long-arrow-alt-right"]}
        className="icon"
      />
    </div>
  );
};

export default MoreInfoButton;
