export const SEND_MESSGAE_START = 'SEND_MESSGAE_START';
export const SEND_MESSGAE_SUCCESS = 'SEND_MESSGAE_SUCCESS';
export const SEND_MESSGAE_FAILED = 'SEND_MESSGAE_FAILED';
export const INIT_MESSAGE_STATE = 'INIT_MESSAGE_STATE';

export const sendMessage = () =>  ({
  type: SEND_MESSGAE_START
})

export const sendMessageSuccess = () =>  ({
  type: SEND_MESSGAE_SUCCESS
})

export const sendMessageFailed = () =>  ({
  type: SEND_MESSGAE_FAILED
})

export const initMessageState = () =>  ({
  type: INIT_MESSAGE_STATE
})
