const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const asyncValidate = (values /*, dispatch */) => {
  return sleep(500).then(() => {
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)){
      // eslint-disable-next-line no-throw-literal
      throw { email: '請輸入正確的電郵格式' }
    }
    if (!values.email) {
      // eslint-disable-next-line no-throw-literal
      throw { email: '請輸入電郵' }
    }
  })
}

export default asyncValidate